import './App.css';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyDvXS37hIva7-wPpMCLUBjY_55KPYZxCL8",
  authDomain: "soundremedy-2b1c0.firebaseapp.com",
  projectId: "soundremedy-2b1c0",
  storageBucket: "soundremedy-2b1c0.appspot.com",
  messagingSenderId: "912035981656",
  appId: "1:912035981656:web:dd6f6d1658da9b76e99d2d",
  measurementId: "G-5VFLTQ4BL9"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);


function App() {
  return (
    <div className="App">
      <div>hello world</div>
    </div>
  );
}

export default App;
